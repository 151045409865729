import { Injectable } from '@angular/core';
import { AppSettings } from '../../utils/app-settings';
import {
  IRequestSigup,
  IRequestGoogleLogin,
  IRequestVerify,
  IRequestRefreshToken,
  IRequestForgotPassword,
  IRequestVerifyForgotPassword,
  IRequestVerifyOtp,
  IRequestLogin,
} from '../../interfaces/auth.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IUserResponse } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettings
  ) {}

  postLogin(payload: IRequestLogin): Observable<IUserResponse> {
    const url = this.appSettings.auth.urls.postLogin;
    return this.httpClient.post<IUserResponse>(url, payload);
  }

  postSignup(payload: IRequestSigup): Observable<void> {
    const url = this.appSettings.auth.urls.postSignup;
    return this.httpClient.post<void>(url, payload);
  }

  postGoogleLogin(payload: IRequestGoogleLogin): Observable<void> {
    const url = this.appSettings.auth.urls.postGoogleLogin;
    return this.httpClient.post<void>(url, payload);
  }

  postRequestVerify(payload: IRequestVerify): Observable<void> {
    const url = this.appSettings.auth.urls.postRequestVerify;
    return this.httpClient.post<void>(url, payload);
  }

  postLogout(): Observable<void> {
    const url = this.appSettings.auth.urls.postLogout;
    return this.httpClient.post<void>(url, {});
  }

  postRefresh(payload: IRequestRefreshToken): Observable<void> {
    const url = this.appSettings.auth.urls.postRefresh;
    return this.httpClient.post<void>(url, payload);
  }

  postForgotPassword(payload: IRequestForgotPassword): Observable<void> {
    const url = this.appSettings.auth.urls.postForgotPassword;
    return this.httpClient.post<void>(url, payload);
  }

  postVerifyForgotPassword(
    payload: IRequestVerifyForgotPassword
  ): Observable<void> {
    const url = this.appSettings.auth.urls.postVerifyForgotPassword;
    return this.httpClient.post<void>(url, payload);
  }

  postVerifyOtp(payload: IRequestVerifyOtp): Observable<void> {
    const url = this.appSettings.auth.urls.postVerifyOtp;
    return this.httpClient.post<void>(url, payload);
  }
}
