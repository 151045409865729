{
  "name": "stonestore-platfform",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:dev": "ng build --configuration=development",
    "build:pdn": "ng build --configuration=production",
    "build:ssr": "npm run build",
    "build:ssr:dev": "npm run build:dev",
    "build:ssr:pdn": "npm run build:pdn",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:stonestore-platfform": "node dist/stonestore-platfform/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.0",
    "@angular/common": "^19.0.0",
    "@angular/compiler": "^19.0.0",
    "@angular/core": "^19.0.0",
    "@angular/forms": "^19.0.0",
    "@angular/platform-browser": "^19.0.0",
    "@angular/platform-browser-dynamic": "^19.0.0",
    "@angular/platform-server": "^19.0.0",
    "@angular/router": "^19.0.0",
    "@angular/ssr": "^19.0.4",
    "@codegenie/serverless-express": "^4.16.0",
    "@ngxs/devtools-plugin": "^19.0.0-dev.master-56b9e7d",
    "@ngxs/logger-plugin": "^19.0.0",
    "@ngxs/storage-plugin": "^19.0.0",
    "@ngxs/store": "^19.0.0-dev.master-56b9e7d",
    "@vendia/serverless-express": "^4.12.6",
    "bootstrap": "^5.3.3",
    "express": "^4.18.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.4",
    "@angular/cli": "^19.0.4",
    "@angular/compiler-cli": "^19.0.0",
    "@types/aws-lambda": "^8.10.146",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "aws-lambda": "^1.0.7",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "serverless-offline": "^14.4.0",
    "typescript": "~5.6.2"
  }
}
