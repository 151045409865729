import {
  IRequestSigup,
  IRequestGoogleLogin,
  IRequestVerify,
  IRequestRefreshToken,
  IRequestForgotPassword,
  IRequestVerifyForgotPassword,
  IRequestVerifyOtp,
  IRequestLogin,
} from '../../interfaces/auth.interface';

export class PostLoginAction {
  static readonly type = '[Auth] POST Login Request';
  constructor(public payload: IRequestLogin) {}
}

export class PostSignupAction {
  static readonly type = '[Auth] POST Signup';
  constructor(public payload: IRequestSigup) {}
}

export class PostGoogleLoginAction {
  static readonly type = '[Auth] POST Google Login';
  constructor(public payload: IRequestGoogleLogin) {}
}

export class PostRequestVerifyAction {
  static readonly type = '[Auth] POST Request Verify';
  constructor(public payload: IRequestVerify) {}
}

export class PostLogoutAction {
  static readonly type = '[Auth] POST Logout';
}

export class PostRefreshTokenAction {
  static readonly type = '[Auth] POST Refresh Token';
  constructor(public payload: IRequestRefreshToken) {}
}

export class PostForgotPasswordAction {
  static readonly type = '[Auth] POST Forgot Password';
  constructor(public payload: IRequestForgotPassword) {}
}

export class PostVerifyForgotPasswordAction {
  static readonly type = '[Auth] POST Verify Forgot Password';
  constructor(public payload: IRequestVerifyForgotPassword) {}
}

export class PostVerifyOtpAction {
  static readonly type = '[Auth] POST Verify OTP';
  constructor(public payload: IRequestVerifyOtp) {}
}
